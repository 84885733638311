.benefitCards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: calc(4 * var(--spacing));
}

.benefitCard {
    flex-basis: 32%;
    padding: var(--spacing--lg);
    width: 100%;
    border: var(--border-width) solid var(--color-gray--20);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing--lg);
}

.benefitCardMainContentContainer {
    display: flex;
    flex-direction: row;
}

.testimonialContainer {
    width: 100%;
    padding: var(--spacing--lg);
    background-color: var(--color-gray--10);
    border-radius: var(--border-radius);
    margin-top: auto;
}

.testimonial {
    text-align: center;
}

.testimonialLeftQuote {
    margin-top: var(--spacing--lg);
    line-height: 0px;
    color: var(--color-brand--primary);
    font-size: 48px;
}

.testimonialRightQuote {
    line-height: 24px;
    font-size: 48px;
    color: var(--color-brand--primary);
    text-align: right;
}

.testimonialName {
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--semi-bold);
    line-height: 24px;
    text-align: right;
}

.benefitCard img {
    padding-top: var(--spacing--sm);
    max-width: 75px;
    margin-right: var(--spacing--md);
}

.benefitCard h2 {
    font-size: var(--font-size--36);
    line-height: var(--line-height--lg);
    margin-bottom: var(--spacing--sm);
}

.content {
    font-size: var(--font-size--16);
    line-height: 24px;
    margin-bottom: var(--spacing--sm);
}

.savingsSubText {
    font-size: var(--font-size--14);
    color: var(--color-gray--40);
    line-height: 21px;
    margin-bottom: var(--spacing--sm);
}

.savingsText {
    font-size: var(--font-size--28);
    font-weight: var(--font-weight--semi-bold);
    line-height: 32px;
    margin-bottom: var(--spacing--x-sm);
}

.benefitCard a {
    text-align: left;
    font-weight: normal;
    font-size: var(--font-size--16);
    line-height: 24px;
    margin-bottom: var(--spacing--md);
}

@media (max-width: 1000px) {
    .benefitCard {
        flex-basis: 49%;
    }
}

@media (max-width: 768px) {
    .benefitCards {
        flex-direction: column;
        margin-top: var(--spacing--x-lg);
        padding-left: var(--spacing--md);
        padding-right: var(--spacing--md);
    }

    .benefitCard:not(:last-child) {
        margin-bottom: var(--spacing--md);
    }

    .benefitCard {
        padding: var(--spacing--md);
    }

    .benefitCard h2 {
        font-size: var(--font-size--24);
    }

    .savingsText {
        font-size: var(--font-size--22);
        line-height: var(--line-height--x-lg);
    }
}
