.videoCard {
    background-color: var(--color-gray--10);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: var(--spacing--x-lg);
    justify-content: space-between;
}

.videoContainer {
    padding: var(--spacing--lg);
    width: 100vw;
    height: calc(9 * 100vw / 16);
    max-width: calc(16 / 9 * 100vh);
    max-height: 100vh;
}

.testimonialContainer {
    flex-direction: column;
    align-self: center;
    padding: var(--spacing--lg);
    width: 100vw;
}

@media (min-width: 769px) {
    .videoCard {
        margin-top: calc(4 * var(--spacing));
        flex-direction: row;
    }

    .videoContainer {
        flex-basis: 49%;
        max-height: 315px;
        max-width: 560px;
        height: 315px;
        width: 560px;
    }

    .testimonialContainer {
        flex-basis: 49%;
        min-width: 50%;
    }
}

.testimonial {
    line-height: var(--font-size--36);
    font-size: var(--font-size--24);
    text-align: center;
}

.testimonialLeftQuote {
    margin-top: var(--spacing--lg);
    line-height: 0px;
    color: var(--color-brand--primary);
    font-size: 96px;
}

.testimonialRightQuote {
    margin-top: var(--spacing--lg);
    line-height: 36px;
    font-size: 96px;
    color: var(--color-brand--primary);
    text-align: right;
}

.testimonialName {
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--semi-bold);
    line-height: var(--font-size--24);
    text-align: right;
}
