.bannerContainer {
    display: flex;
    margin: 16px;
    border-radius: 15px;
}

@media screen and (max-width: 764px){
    .bannerContainer{
       flex-direction: column;
    }
 }

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: 37%;
}

.textContent {
    padding: 29px 38px;
}

.bannerTitle {
    font-size: var(--font-size--36);
    font-weight: 600;
    line-height: var(--line-height--lg);
    margin-bottom: var(--spacing--sm);
}

.bannerSubtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
}

.bannerHeadline {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.body {
    margin: 26px 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}

.link {
    text-decoration: none;
}

.imageContainer {
    display: flex;
    min-width: 200px;
    width: 100%;
    flex-basis: 63%;
}

.image {
    background-position: right;
    height: 100%;
    max-width: 100%;
    min-width: 300px;
    object-fit: cover;
}