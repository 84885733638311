.footer {
    margin-top: calc(4 * var(--spacing));
    margin-bottom: calc(4 * var(--spacing));
    font-size: var(--font-size--14);
}


@media (max-width: 1220px) {
    .footer {
        margin: var(--spacing--x-lg) var(--spacing--md)
    }
}
