.sliderContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.sliderWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.sliderContent {
    display: flex;
    transition: all 250ms linear;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-padding: 100px;
    scrollbar-width: none;
    border: 1px solid var(--color-gray--20);
}

.sliderContent::-webkit-scrollbar,
.sliderContent:hover::-webkit-scrollbar {
    display: none;
}

.sliderCard {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 210px;
    border: 1px solid #ccc;
    padding: 16px;
}


.sliderCard {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 210px;
    border: 0;
    border-right: 1px solid var(--color-gray--20);
    padding: 16px;
}

.sliderCard:last-child {
    border: 0;
}

.sliderCard section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.sliderCard h2 {
    font-size: var(--font-size--16);
    line-height: 20px;
}

.sliderCard img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: var(--spacing--md);
}

.sliderCard .buttonContainer {
    display: flex;
    flex-grow: 1;
    margin-top: var(--spacing--md);
}

.sliderCard .buttonContainer button {
    width: 100%;
    display: flex;
    align-self: flex-end;
    justify-content: center;
}

.sliderContent .leftScroll,
.sliderContent .rightScroll {
    border: 0;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    position: absolute;
    right: 0;
    top: calc(50% - 15px);
    background-color: var(--color-brand--primary);
    opacity: 0.5;
    z-index: 99;
}

.sliderContent .leftScroll:hover,
.sliderContent .rightScroll:hover {
    opacity: .75;
    background-color: var(--color-brand--hover);
}

.sliderContent .leftScroll {
    left: 14px;
}

.sliderContent .leftScroll svg {
    position: relative;
    right: 1px;
}

.sliderContent .rightScroll {
    right: 14px;
}

.sliderContent .rightScroll svg {
    position: relative;
    left: 1px;
}
