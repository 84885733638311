.styledBlockContent a {
    font-weight: var(--font-weight--semi-bold);
    color: var(--color-brand--primary);
    text-decoration: none;
}

.styledBlockContent a:hover {
    color: var(--color-brand--hover);
    text-decoration: underline;
}

.styledBlockContent strong {
    font-weight: var(--font-weight--semi-bold);
}
