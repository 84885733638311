
.imageBannerLink {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.bannerImage {
    width: 100%;
    height: auto;
}

.bannerDiv {
    margin: auto;
    display:flex;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: calc(3.2 * var(--spacing));
    margin-bottom: auto;
    text-align: center;
    
}
