.questions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: calc(4 * var(--spacing));
}

.questionsContainer {
    flex-basis: 65%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: var(--spacing--x-lg);;
}

@media (max-width: 927px) {
    .questions {
        margin-top: var(--spacing--x-lg);
        flex-direction: column;
    }

    .questionsContainer {
        margin-bottom: var(--spacing--lg);
    }
}

@media (max-width: 1024px) {
    .questionsContainer {
        flex-basis: 60%;
    }
}

.imageContainer {
    margin-left: auto;
    margin-right: auto;
}

.imageContainer picture {
    display: flex;
}

.questions h3 {
    font-size: var(--font-size--28);
    line-height: 32px;
}

@media (max-width: 961px) {
    .questionsContainer {
        margin-left: var(--spacing--md);
        margin-right: var(--spacing--md);
    }
}

@media (max-width: 961px) {
    .questions {
        margin-left: var(--spacing--md);
    }
}

.questions p {
    font-size: var(--font-size--16);
    line-height: 24px;
    margin-bottom: var(--spacing--md);
}

.questionLinkContainer a {
    text-align: left;
    font-weight: var(--font-weight--regular);
}

.questionLinkContainer a:not(:last-child) {
    margin-bottom: var(--spacing--md);
}
