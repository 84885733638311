.hero {
    position: relative;
    padding: 0 !important;
    min-height: 225px;
    background: var(--color-gray--10) !important;
    border: none !important;
    border-radius: 0 !important;
}

.hero > picture {
    display: none;
}

.heroContent {
    width: 100%;
    text-align: center;
    padding: var(--spacing--md);
}

.heroContent img {
    max-width: 250px;
    margin-bottom: var(--spacing--sm-md);
}

.heroContent h1 {
    font-size: var(--font-size--30);
    margin-bottom: var(--spacing--sm-md);
    line-height: var(--line-height--lg);
}

.minorTagline {
    font-size: var(--font-size--18);
    margin-bottom: var(--spacing--sm-md);
    line-height: var(--line-height--x-lg);
}

.additionalText {
    font-size: var(--font-size--16);
    margin-bottom: var(--spacing--sm-md);
    line-height: var(--line-height--x-lg);
}

@media (max-width: 944px) {
    .heroContent a {
        padding-right: calc(4 * var(--spacing));
        padding-left: calc(4 * var(--spacing));
        margin-bottom: var(--spacing--sm);
    }
    .heroContent img {
        margin-top: var(--spacing--sm);
    }
}

@media (min-width: 945px) {
    .hero > picture {
        width: 100%;
        display: flex;
    }

    .heroContent img {
        max-width: 275px;
    }

    .heroContent h1 {
        font-size: var(--font-size--36);
        line-height: var(--line-height);
    }

    .minorTagline {
        font-size: var(--font-size--22);
    }

    .heroContent {
        max-width: 480px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        width: auto;
        text-align: left;
        padding: 0;
    }
}

@media (min-width: 1100px) {
    .heroContent picture {
        max-width: 325px;
        margin-bottom: var(--spacing--lg);
    }

    .heroContent h1 {
        font-size: var(--font-size--42);
    }

    .minorTagline {
        font-size: var(--font-size--24);
    }

    .additionalText {
        font-size: var(--font-size--16);
    }

    .heroContent {
        max-width: 600px;
        left: 70px;
        text-align: center;
    }
}
