.callToAction {
    background-color: var(--color-gray--10);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: var(--spacing--x-lg);
    padding-right: 0;
}

.callToActionButtons {
    margin-top: var(--spacing--md);
    display: flex;
    flex-direction: row;
}

.callToActionButtons button:not(:first-child) {
    margin-left: var(--spacing--sm);
}

.callToActionImageContainer {
    margin: auto;
}

.callToActionImageContainer img {
    width: 100%;
    max-width: 300px;
}

.callToActionContentContainer {
    flex-basis: 65%;
}

.callToActionContentContainer h3 {
    font-size: var(--font-size--36);
    line-height: 41px;
}

.callToActionContentContainer h4 {
    font-size: var(--font-size--28);
    line-height: 32px;
}

.callToActionContent p {
    font-size: var(--font-size--16);
    line-height: 24px;
}

@media (max-width: 927px) {
    .callToActionContentContainer h3 {
        font-size: var(--font-size--24);
        line-height: var(--line-height--x-lg);
    }

    .callToActionContentContainer h4 {
        font-size: var(--font-size--22);
        line-height: var(--line-height--x-lg);
    }

    .callToActionContentContainer p {
        font-size: var(--font-size--16);
        line-height: var(--line-height--x-lg);
    }

    .callToAction {
        padding-right: var(--spacing--x-lg);
        flex-direction: column;
    }

    .callToActionImageContainer {
        margin-top: var(--spacing--lg);
    }

    .callToActionButtons {
        flex-direction: column;
    }

    .callToActionButtons button {
        align-self:flex-start;
    }

    .callToActionButtons button:not(:first-child) {
        margin-left: 0;
        margin-top: var(--spacing--sm);
    }

    .callToActionContentContainer {
        width: 100%;
        padding-bottom: var(--spacing--lg);
        border-bottom: var(--color-gray--30) var(--border-width) solid;
    }
}

@media (max-width: 1024px) {
    .callToActionContentContainer {
        flex-basis: 60%;
    }
}

@media (min-width: 927px) {
    .callToActionContentContainer {
        border-right: var(--color-gray--30) var(--border-width) solid;
    }
}
