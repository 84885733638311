.bonusPerkList {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-top: var(--spacing--x-lg);
    padding-left: var(--spacing--md);
    padding-right: var(--spacing--md);
}

@media (min-width: 769px) {
    .bonusPerkList {
        flex-direction: row;
        margin-top: var(--spacing--x-lg);
        padding: 0;
    }
}

.bonusPerkListHeaderCard {
    padding: var(--spacing--lg);
    border: var(--border-width) solid var(--color-gray--20);
    display: flex;
    flex-direction: column;
}

.bonusPerkListHeader {
    display: flex;
    flex-direction: row;
}

.bonusPerkListHeader img {
    padding-top: var(--spacing--sm);
    max-width: 75px;
    margin-right: var(--spacing--md);
}

.bonusPerkListHeader h2 {
    font-size: var(--font-size--24);
    line-height: var(--line-height--lg);
    margin-bottom: var(--spacing--sm);
}

.content {
    font-size: var(--font-size--16);
    line-height: 24px;
    margin-bottom: var(--spacing--sm);
}

.savingsSubText {
    font-size: var(--font-size--14);
    color: var(--color-gray--40);
    line-height: 21px;
    margin-bottom: var(--spacing--sm);
}

.savingsText {
    font-size: var(--font-size--22);
    font-weight: var(--font-weight--semi-bold);
    line-height: 32px;
    margin-bottom: var(--spacing--x-sm);
}

.bonusPerksSliderContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
}

@media (min-width: 769px) {
    .bonusPerkList .bonusPerksSliderContainer {
        flex-direction: row;
        width: 64%;
    }
}

@media (min-width: 48.0625rem) {
    .bonusPerkListHeaderCard {
        border-right: 0;
    }
}

@media (min-width: 769px) {
    .bonusPerksSliderContainer {
        flex-direction: row;
    }

    .bonusPerkListHeader h2 {
        font-size: var(--font-size--36);
    }

    .savingsText {
        font-size: var(--font-size--28);
    }
}
